import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {useNavigate} from 'react-router-dom'



import "../Sales/Sales.css"
import { FaHouseUser, FaUserPlus, FaHandshake, FaHome , FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import {estateConfig, salesConfig} from "./../../components/PageConfig"

const Estate = () => {
    const navigate = useNavigate()


        return(
            <Sidebar menuItems = {estateConfig} >
            <main id='sales-container' >
                
                    <section className='sales-group-card' >
                    

                        <h1>Estate</h1>

                    </section>
                
                
            </main>
            </Sidebar>
        )
    


}

export default Estate;