import React, {Component} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {baseUrl} from '../../Config/URL'
import {loadingHandler} from '../../components/Loading'

import './Login.css'

import chlLogo from '../../Assets/CHL-Logo2.png'

class Login extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password:"",
            isLoading: false
        }
    }

    componentDidUpdate(){
        loadingHandler(this.state.isLoading)
    }

    loginButton = (e) => {
        e.preventDefault();
        
        const {email, password} = this.state
        this.setState({isLoading: true})
        axios.post(baseUrl+"login",{
            email,
            password
        }).then(res => {
            console.log(res.data);
            this.setState({isLoading: false})
            sessionStorage.setItem('TokenCHL', JSON.stringify(res.data))
            window.location.reload();
        }).catch(err => {
            this.setState({isLoading: false})
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({isLoading: false})

                }
              })
        })


        if(this.state.emailLogin === "" || this.state.passwordLogin === ""){
            Swal.fire({
                title: 'Isi Username & password anda',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              })
        }else if(this.state.emailLogin !== "admin"){
            Swal.fire({
                title: 'Wrong Username',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              })
        }else if(this.state.passwordLogin !== "admin"){
            Swal.fire({
                title: 'Wrong Password',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              })
        }
        else{
            localStorage.setItem('TokenCHL', "RanDomStoRageToken")
            window.location.reload();
        }

        
    
      }

    render(){
        return(

            <main className="login-body">
                <div class="tengah">
                    <img class="img-responsive" src={chlLogo}/>
                </div>
                <div class="container-login">
                    <div class="login">
                        <form className="login-form" onSubmit={this.loginButton}>
                            <h2>Mazenta</h2>
                            <h1>Login</h1>
                            <hr/>
                            <label for="">Email</label>
                            <input onChange={(e) => this.setState({email: e.target.value})} type="email" placeholder="Email"/>
                            <label for="">Password</label>
                            <input onChange={(e) => this.setState({password: e.target.value})} type= "password" placeholder="Password"/>
                            <button>login</button>
                        </form>
                    </div>
                </div>
            </main>


            // <main id="login-main" >
                
            //     <section className="box" id="logo-box" >
            //         <img src={chlLogo} alt="Login" />
            //         <h2>Mazenta</h2>
            //     </section>

            //     <section className="box" id="login-box" >
            //         <h3>Login</h3>
            //         <form onSubmit={this.loginButton} >
            //             <label>Username : <input onChange={(e) => this.setState({emailLogin: e.target.value})} type="text" placeholder="Username" /></label>
            //             <label>Password : <input onChange={(e) => this.setState({passwordLogin: e.target.value})} type= "password" placeholder="Password" /></label>
            //             <button type="submit"  id="login-button" >Login</button>
            //         </form>
            //         <div className="button-group" >
            //             {/* <Link id="link-button" to='/home' ><button>Click</button></Link> */}
            //             {/* <button type="submit"  id="login-button" >Login</button> */}
                        
            //         </div>
            //     </section>

            // </main>
        )
    }

}

export default Login;